import styled from 'styled-components'
import { Device } from '../../components/core/responsive'

export const TeamWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
`
export const TeamContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 920px;
  flex-direction: column;
  margin: 0 10px;

  @media (${Device.laptop}){
    margin: 0;
    flex-direction: row;
  }
`
