import styled from 'styled-components'
import { Fonts } from '../../core/fonts'
import { Colors } from '../../core/colors'
import { motion } from 'framer-motion'
import Img from 'gatsby-image'
import { Device } from '../../core/responsive'

export const EmployeeWrapper = styled(motion.div)`
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  border-radius: 2px;
  min-height: 225px;
  min-width: 40%;
  margin: 24px;
  
  @media (${Device.laptop}){
    flex-direction: row;
  }
`

export const EmployeeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  width: 100%;
`

export const Name = styled.h3`
  position: relative;
  font-weight: 900;
  font-size: 1.1rem;
  font-family: ${Fonts.default};
  color: ${Colors.text};
  font-stretch: condensed;
  text-transform: uppercase;
  margin-bottom: 12px;
`

export const Title = styled.h4`
  position: relative;
  font-weight: 600;
  font-size: 1rem;
  font-family: ${Fonts.default};
  color: ${Colors.text};
  font-stretch: condensed;
  margin-bottom: 12px;
`
export const Role = styled.h4`
  position: relative;
  font-weight: 900;
  font-size: .9rem;
  font-family: ${Fonts.default};
  color: ${Colors.primary};
  text-wrap: normal;
  max-width: 100%;
  text-align: center;
  font-stretch: condensed;
  margin-bottom: 12px;
`
export const Tel = styled.a`
  position: relative;
  font-family: ${Fonts.default};
  color: ${Colors.text};
  font-stretch: condensed;
  margin-bottom: 12px;
  
  &:hover,
  &:focus{
    color: ${Colors.primary}
  }
`
export const Email = styled.a`
  position: relative;
  font-family: ${Fonts.default};
  color: ${Colors.text};
  font-stretch: condensed;
  margin-bottom: 12px;
  cursor: pointer;
  
  &:hover,
  &:focus{
    color: ${Colors.primary}
  }
`

export const ImageContainer = styled(motion.div)`
  display: flex;
  position: relative;
  align-items: center;
  left: 20px;
  width: 150px;
    
  @media (${Device.laptop}){
    left: 0;
  }
`

export const Diamond = styled(motion.div)`
  width: 100px;
  height: 100px;
  transform: rotate(-45deg);
  overflow: hidden;
  box-shadow: 0 15px 45px -9px rgba(0,0,0,.2);
`

export const Image = styled(Img)`
  width: 150%;
  height: 150%;
  margin-left: -20px;
  margin-top: -25px;
  transform: rotate(45deg);
  filter: grayscale(100%);
`
