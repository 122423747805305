import * as React from 'react'
import { CenteredCard } from '../../components/shared/card'
import Layout from '../../layout'
import { MediumHeader } from '../../components/shared/header'

import * as team from '../../content/team.yml'
import Employee from '../../components/shared/employee'
import { TeamContainer, TeamWrapper } from './styles'
import { graphql } from 'gatsby'

export default class TeamPage extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Layout location={'/team'}>
        <MediumHeader title={'Unser Team'} srcSet={this.props.data.headerBackground.childImageSharp.fluid.srcSet}/>
        <CenteredCard centered label={'Für (technische) Fragen und Planungen stehen wir Ihnen jederzeit zur Verfügung.'}
                      headline={'Team'}/>
        <TeamWrapper>
          <TeamContainer>
          {team.map((member, key) => <Employee employee={member} key={key}/>)}
          </TeamContainer>
        </TeamWrapper>
      </Layout>
    )
  }
}


export const TeamQuery = graphql`{
    headerBackground: file(name: {eq: "team-header"}) {
        childImageSharp {
            fluid {
                srcSet
            }
        }
    }
}
`
