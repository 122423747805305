import React from 'react'
import {
  Diamond,
  Email,
  EmployeeContainer,
  EmployeeWrapper,
  Image,
  ImageContainer,
  Name,
  Role,
  Tel,
  Title,
} from './styles'
import { graphql, StaticQuery } from 'gatsby'
import { DiamondAnimation, EmployeeAnimation } from './animations'

export const fluidImage = graphql`
    fragment fluidImage on File {
        childImageSharp {
            fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
            }
        }
    }
`


const EmployeeImageQuery = graphql`
    query {
        matthias: file(relativePath: { eq: "images/team/matthias-goetz.jpg" }) {
            ...fluidImage
        }
        daniel: file(relativePath: { eq: "images/team/daniel-wendl.jpg" }) {
            ...fluidImage
        }
        placeholder: file(relativePath: { eq: "images/team/placeholder.jpg" }) {
            ...fluidImage
        }
        johannes: file(relativePath: { eq: "images/team/johannes-schindler.jpg" }) {
            ...fluidImage
        }
        nina: file(relativePath: { eq: "images/team/nina-gierstorfer.jpg" }) {
            ...fluidImage
        }
        axel: file(relativePath: { eq: "images/team/axel-ginghuber.jpg" }) {
            ...fluidImage
        }
        sebastian: file(relativePath: { eq: "images/team/sebastian-brandl.jpg" }) {
            ...fluidImage
        }
    }
`


export default class Employee extends React.Component {

  constructor(props) {
    super(props)
  }


  renderNavigation({ employee, images }) {
    const { name, title, role, tel, email, image } = employee

    return (
      <EmployeeWrapper {...EmployeeAnimation}>
        <ImageContainer>
          <Diamond {...DiamondAnimation}>
            <Image fluid={images[image].childImageSharp.fluid} alt={name}/>
          </Diamond>
        </ImageContainer>

        <EmployeeContainer>
          <Name>{name}</Name>
          <Role>{role}</Role>
          <Title>{title}</Title>
          <Tel href={`tel:${tel}`}>{tel}</Tel>
          <Email href={`mailto:${email}`}>{email}</Email>
        </EmployeeContainer>
      </EmployeeWrapper>
    )
  }

  render() {
    return <StaticQuery
      query={EmployeeImageQuery}
      render={data => this.renderNavigation({
        employee: this.props.employee,
        images: data,
      })}
    />
  }
}
