import { Colors } from '../../core/colors'

export const EmployeeAnimation = {
  initial: {
    top: '-50px',
    opacity: 0,
  },
  animate: {
    top: '0',
    opacity: 1,
  },
  transition: {
    delay: .5,
    type: 'spring',
    damping: 20,
  },
}

export const DiamondAnimation = {
  initial: {
    transform: 'rotate(-45deg)',
    borderTop: `0px solid ${Colors.primary}`,
  },
  whileHover: {
    transform: 'rotate(-40deg)',
    borderTop: `10px solid ${Colors.primary}`,
    transition:{
      ease: 'easeIn'
    }
  },
}
